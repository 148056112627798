<template>
    <div class="upsell-modal-container">
        <div class="modal-header">
            <div @click="closeModal" class="cursor-pointer">
                <close-icon class="!fill-slate-700" />
            </div>
        </div>

        <div class="modal-body">
            <div class="left-side">
                <div class="flex gap-3">
                    <div class="pb-logo-container">
                        <pb-lock-logo />
                    </div>

                    <div>
                        <h1>Upgrade to Privacy Bee Pro</h1>
                        <span class="upsell-paragraph">
                            Some really fancy and catching tagline talking about how Privacy Bee will totally keep you and your
                            data private.
                        </span>
                    </div>
                </div>

                <div class="divider"></div>

                <div class="flex flex-col gap-6">
                    <div class="upsell-note" v-for="note in upsell_notes">
                        <checkmark-icon />
                        <p>
                            <span class="text-style-link">{{ note.title }}</span> {{ note.description }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="right-side">
                <div class="payment-container">
                    <div class="flex flex-col gap-4">
                        <div class="sub-duration-badge">
                            <label class="text-slate-500">Annual Subscription</label>
                        </div>

                        <div class="flex items-center gap-3">
                            <h1 class="text-[40px]">$197.00</h1>
                            <div class="per-year">per year</div>
                        </div>
                    </div>

                    <div class="divider"></div>

                    <PrimeInputMask id="basic" v-model="value" mask="99/99" placeholder="MM/DD" />
                </div>

                <div class="divider relative !mt-8">
                    <div class="flex justify-center absolute w-full top-[-10px]">
                        <span class="recommended-text">#1 Recommended by Privacy Professionals</span>
                    </div>
                </div>

                <div class="company-logos-container">
                    <img src="@/assets/images/upsell-companies/pc-mag.png" />
                    <img src="@/assets/images/upsell-companies/vb.png" />
                    <img src="@/assets/images/upsell-companies/readers-digest.png" />
                    <img src="@/assets/images/upsell-companies/ibt.png" />
                    <img src="@/assets/images/upsell-companies/zd-net.png" />
                    <img src="@/assets/images/upsell-companies/business-com.png" />
                    <img src="@/assets/images/upsell-companies/m-series.png" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import CloseIcon from '@/assets/icons/close.svg';
import CheckmarkIcon from '@/assets/icons/checkmark.svg';
import PbLockLogo from '@/assets/branding/small-lock-logo.svg';
import PrimeInputMask from 'primevue/inputmask';

import { inject } from 'vue';

const dialogRef = inject('dialogRef');

const upsell_notes = [
    {
        title: 'Delete your contact info',
        description: 'from hundreds of Data Brokers and People Search Sites',
    },
    {
        title: 'Scrub your publicly exposed info',
        description: 'from Google and other search engines',
    },
    {
        title: 'Zero-Trust browser extension',
        description: 'for surfing the web without being tracked',
    },
    {
        title: '24/7/365 monitoring',
        description: 'for proactive action against re-exposures or data breaches',
    },
    {
        title: 'Dozens',
        description: 'more privacy-focused features and tools',
    },
];

function closeModal() {
    dialogRef.value.close();
}
</script>

<style lang="scss" scoped>
.upsell-modal-container {
    @apply bg-white-700 rounded-md max-w-[97vw];
    @apply lg:w-fit  xl:max-w-[1076px];
}

.modal-header {
    @apply h-12 flex justify-end items-center px-3;
}

.modal-body {
    //edit scrollbar
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: theme('colors.slate-300');
        border-radius: 4px;
    }

    @apply grid-cols-1 px-6 pb-6 overflow-y-scroll max-h-[90vh];
    @apply xl:px-12 xl:pb-12 grid gap-8 xl:grid-cols-2;
}

// body left-side
.pb-logo-container {
    @apply bg-slate-700 min-w-8 h-8 flex justify-center items-center rounded-full relative top-2;
}

.upsell-note {
    @apply flex gap-4 items-center text-slate-700;

    p {
        color: theme('colors.slate-700') !important;
    }
}

.divider {
    @apply bg-slate-200 w-full h-[2px] my-6;
}

.upsell-paragraph {
    @apply text-slate-600 font-inter text-xs font-normal leading-4;
}

.sub-duration-badge {
    @apply border-blue-300 bg-white-700 border px-3 rounded-full w-fit flex justify-center py-1;
}

// body right-side
.payment-container {
    @apply bg-blue-200 p-8 rounded-lg h-[455px];
}

.per-year {
    @apply text-slate-600 font-inter text-sm font-normal leading-5;
}

.recommended-text {
    @apply text-center text-slate-600 text-xs font-semibold leading-5 bg-white-700 px-3;
}

.company-logos-container {
    @apply grid grid-flow-col items-center justify-between;

    img {
        @apply max-w-9;
    }
}
</style>
