//components
import UpsellModal from '~/components/modals/personal/UpsellModal.vue';

//allosed names in typescript
type DialogName = 'personal:upsell' | 'test';

export function useDynamicDialog(name: DialogName) {
    const nuxtApp = useNuxtApp();

    if (name == 'personal:upsell') {
        return nuxtApp.$dialog.open(UpsellModal, {
            props: {
                modal: true,
                closable: false,
                dismissableMask: true,
                pt: {
                    mask: 'bg-slate-600',
                },
            },
        });
    }
}
